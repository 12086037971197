<template>
  <div
    v-show="item.data?.length"
    ref="refSelectList"
    :class="{
      'filter-select-list': true,
      'filter-select-list__filter-side': isFilterSide
    }"
  >
    <div
      v-show="isShowTitle"
      class="filter-select-list__header"
      @click="toggle"
    >
      <span class="filter-select-list__header-title">{{ item.label }}</span>
      <span
        v-if="showTitleRight"
        class="filter-select-list__header-right"
        aria-hidden="true"
      >
        <span
          v-if="isShowViewAll"
          class="badge"
        >
          {{ language?.SHEIN_KEY_PWA_25684 || '' }}
        </span>
        <i 
          :class="iconClassName"
        ></i>
      </span>
    </div>
    <s-slide
      ref="slide"
      :visible.sync="open"
      @opened="onOpened"
    >
      <div
        ref="refMain"
        class="filter-select-list__main"
      >
        <SelectItem
          v-for="(obj, i) in item.data"
          :key="i"
          :data-type="obj?.dataType || dataType"
          :item="obj"
          :active="!!obj.active"
          :disabled="disabled"
          @clickItem="clickMulti(obj, i)"
        />
      </div>
    </s-slide>
  </div>
</template>

<script>
import Vue from 'vue'
import { Slide } from '@shein/sui-mobile'
import SelectItem from './SelectItem'

Vue.use(Slide)

export default {
  name: 'SelectList',
  inject: {
    constantData: {
      default: () => ({})
    }
  },
  components: {
    SelectItem
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    isShowTitle: {
      type: Boolean,
      default: () => true
    },
    language: {
      type: Object,
      default: () => ({})
    },
    dataType: {
      type: String,
      default: ''
    },
    // 是否Filter侧边栏
    isFilterSide: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      open: false,
      openSecond: false, // 属性外露筛选面板使用，记录当前是否展开
      isExceedOneLine: false, // 是否超过一行
    }
  },
  computed: {
    // 是否展示title右侧 箭头&label【当前为属性外露&不超过一行时，隐藏】
    showTitleRight() {
      return !(!this.isFilterSide && !this.isExceedOneLine)
    },
    isShowViewAll () {
      return !this.isFilterSide && !this.openSecond
    },
    iconClassName () {
      const flag  = this.isFilterSide ? this.open : this.openSecond

      return flag ? 'suiiconfont sui_icon_more_up_16px' : 'suiiconfont sui_icon_more_down_16px'
    }
  },
  watch: {
    'item': {
      handler(val, oldVal) {
        if (oldVal) {
          this.calHeight()
          return
        }

        this.open = !this.isShowTitle || !this.isFilterSide
        this.handleMultiLine()
      },
      immediate: true
    },
    isShowTitle: {
      handler(val) {
        if (!val) {
          this.open = true
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.open = !this.isShowTitle || !this.isFilterSide || Boolean(this.item.data.find(item => item.active))
  },
  methods: {
    toggle () {
      this.isFilterSide
        ? this.toggleInFilter()
        : this.toggleInPanel()
    },
    // 侧边栏toggle，正常展开/收起
    toggleInFilter() {
      this.setParentHeight()
      this.open = !this.open
      this.$emit('toggle', this.open)
    },
    // 属性外露toggle，open均为true【收起时，展示一行】
    toggleInPanel() {
      this.openSecond = !this.openSecond
      this.handleMultiLine()
      this.$emit('toggle', this.openSecond)
    },
    // 设置父级高度，避免固定高度导致当前内容无法展示
    setParentHeight() {
      const el = this.$refs.refSelectList
      const pEl = $(el.closest('.S-slide'))
      pEl.css('height', '') // 清除父级元素固定宽度，否则会影响内部展开/收起
    },
    clickItem (payload) {
      this.$emit('clickItem', payload)
    },
    onOpened () {
      if (!this.isFilterSide) return

      const el = this.$refs.refMain
      const bottomHeight = 44 / 375 * window.innerWidth // 底部按钮高度
      const rect = el.getBoundingClientRect()
      if (rect.bottom + bottomHeight > window.innerHeight) {
        el.scrollIntoView({ block: 'end', behavior: 'smooth' }) // 不在可视区域内，滚动到可视区域
      }
    },
    clickMulti(item, index) {
      if (this.disabled) return
      
      this.$emit('clickItem', item, index)
    },
    getAllChildDom() {
      return $(this.$refs.refSelectList).find('.select-item')
    },
    async calHeight () {
      this.isFilterSide && this.setParentHeight()
      await this.$nextTick()
      this.$refs.slide?.calHeight()
    },
    async handleMultiLine() {
      this.isExceedOneLine = false
      if (this.isFilterSide || !this.item.data?.length ) return
      await this.$nextTick()
      const parentDom = this.$refs.refSelectList
      const wrapDom = parentDom.querySelector('.filter-select-list__main')
      const childList = parentDom.querySelectorAll('.filter-select-item')
      childList.forEach(child => child.classList.remove('filter-select-item_hide'))
      const wrapTop = wrapDom.getBoundingClientRect().top
      let index = childList.length - 1
      let child = childList[index]
      let top = child.getBoundingClientRect().top
      this.isExceedOneLine = Math.abs(top - wrapTop) > 3 // 误差范围3px
      this.openSecond && this.calHeight()
      if (this.openSecond || !this.isExceedOneLine) return

      while (index && child) {
        child.classList.add('filter-select-item_hide')
        index--
        child = childList[index]
        top = child.getBoundingClientRect().top
        if (Math.abs(top - wrapTop) < 3) break // 当前子节点在第一行时，停止隐藏
      }
      this.calHeight()
      this.$emit('toggle', this.open)
    }
  }
}
</script>

<style lang="less" scoped>
.filter-select-list {
    background: #fff;
    margin-bottom: 14px;
    width: 100%;

    &__filter-side {
      .filter-select-list__header {
        background: #f4f4f4;
      }
    }
    
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 20px;
      padding: 0 3px;
      font-weight: 500;
      font-size: 12px;
      
      [class*="iconfont"] {
        .font-dpr(32px);
        font-weight: normal;
        color: #959595;
      }

      &-right {
        .flexbox();
        .align-center();
        .badge {
          .text-overflow();
          .font-dpr(24px);
          max-width: 1.7067rem;
          color: #959595;
          font-family: "SF UI Text";
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
    &__main {
      .flexbox();
      flex-wrap: wrap;
      margin-top: 10px;
    }
  }
</style>
